export enum ResourceTypes {
  messageBanner = 'messageBanner',
  reelLogo = 'reelLogo',
  reelsLight1 = 'reelsLight1',
  reelsLight1Free = 'reelsLight1Free',
  reelsLight2 = 'reelsLight2',
  reelsLight2Free = 'reelsLight2Free',
  reelsLight3 = 'reelsLight3',
  reelsLight3Free = 'reelsLight3Free',
  reelsLight4 = 'reelsLight4',
  reelsLight4Free = 'reelsLight4Free',
  reelsLight5 = 'reelsLight5',
  reelsLight5Free = 'reelsLight5Free',
  frameMobile = 'frameMobile',
  frame = 'frame',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurJack = 'blurJack',
  blurAce = 'blurAce',
  blurAnubis = 'blurAnubis',
  blurEye = 'blurEye',
  blurKing = 'blurKing',
  blurMask = 'blurMask',
  blurNine = 'blurNine',
  blurQueen = 'blurQueen',
  blurRa = 'blurRa',
  blurScarab = 'blurScarab',
  blurTen = 'blurTen',
  mystery01 = 'mystery01',
  mystery02 = 'mystery02',
  ace = 'ace',
  anubis = 'anubis',
  eye = 'eye',
  jack = 'jack',
  king = 'king',
  man = 'man',
  mask = 'mask',
  nine = 'nine',
  pyramid = 'pyramid',
  queen = 'queen',
  ra = 'ra',
  scarab = 'scarab',
  ten = 'ten',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introLight = 'introLight',
  introLogo = 'introLogo',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  popupBg = 'popupBg',
  slotBg = 'slotBg',
  slotFreeSpinBg = 'slotFreeSpinBg',
}
