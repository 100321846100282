import { isMobile } from 'mobile-device-detect';

import variables from '../../assets/styles/export.module.scss';
import { EventTypes } from '../../global.d';
import { setSlotConfig } from '../../gql';
import { calcBottomContainerHeight } from '../../utils';
import ViewContainer from '../components/container';
import {
  FEATURE_BTN_WIDTH,
  SAFE_AREA_DESKTOP_BOTTOM_PADDING,
  SAFE_AREA_MOBILE_BOTTOM_PADDING,
  eventManager,
} from '../config';

class SafeArea extends ViewContainer {
  private isEnabledFreeSpins: boolean;

  constructor() {
    super();
    this.isEnabledFreeSpins = setSlotConfig().isBuyFeatureEnabled;
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private calculateSize(width: number, height: number): [number, number, number] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomSpace = bottomContainerHeight + bottomPadding;

    const isRectangleRatio = +(width / height).toFixed(2) >= +(4 / 3).toFixed(2);
    if (isRectangleRatio) {
      if (width >= parseInt(variables.breakpointMobilePortraitMax) && isMobile && this.isEnabledFreeSpins) {
        const isReduced = width < ((height - bottomSpace) * 4) / 3 + FEATURE_BTN_WIDTH * 2;
        const nHeight = isReduced ? height - FEATURE_BTN_WIDTH * 2 : height;
        newWidth = ((nHeight - bottomSpace) * 4) / 3 - 100;
        newHeight = nHeight - bottomSpace;
      } else {
        newWidth = ((height - bottomSpace) * 4) / 3;
        newHeight = height - bottomSpace;
      }
    } else if (
      width >= parseInt(variables.breakpointMobilePortraitMax) &&
      width >= height &&
      isMobile &&
      this.isEnabledFreeSpins
    ) {
      const nWidth = width - FEATURE_BTN_WIDTH * 3;
      newWidth = nWidth;
      newHeight = nWidth * 0.75 - bottomSpace;
    } else {
      newWidth = width;
      newHeight = width * 0.75 - bottomSpace;
    }

    return [newWidth, newHeight, bottomSpace];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);

    this.position.set(Math.max((width - newWidth) / 2, 0), Math.max((height - newHeight - bottomSpace) / 2, 0));

    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, newWidth, newHeight, this.position);
  }
}
export default SafeArea;
