import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, IAuthInput } from '../../global.d';
import {
  configGql,
  getProgressGql,
  setCurrentBonus,
  setIsTimeoutErrorMessage,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
} from '../../gql';
import { IConfig } from '../../gql/d';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import IntroScreen from '../IntroScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { data: dataProgress } = useQuery<{
    progress: {
      status: number;
      wasLoaded?: boolean;
    };
  }>(getProgressGql);
  const { i18n } = useTranslation();
  const { progress } = dataProgress!;

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.POST_RENDER, () => {
      if (setReplayBet()) {
        eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
        setImmediate(() => {
          const replayFreeSpins = setReplayFreeSpinBets();
          if (replayFreeSpins.length) {
            const triggerSpin = replayFreeSpins.shift();
            setReplayBet(triggerSpin);
          }
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          if (setReplayFreeSpinReelSetId()) {
            eventManager.emit(EventTypes.CHANGE_REEL_SET, {
              reelSet: setSlotConfig().reels.find((reels) => reels.id === setReplayFreeSpinReelSetId()),
              reelPositions: [0, 0, 0, 0, 0],
            });
          }
          eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
            if (state === SlotMachineState.IDLE) {
              if (
                !replayFreeSpins.length ||
                !setCurrentBonus().isActive ||
                setCurrentBonus().currentRound === replayFreeSpins.length
              ) {
                setIsTimeoutErrorMessage(true);
                setStressful({
                  show: true,
                  type: 'none',
                  message: i18n.t('replayBetMessage'),
                });
              }
            }
          });
        });
      }
    });
  }, [i18n]);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);
  return (
    <>
      <LoadScreen />
      {progress?.status >= 80 && <IntroScreen />}
      {data?.isAuthorized && progress?.status === 100 && <GameScreen />}
    </>
  );
};

export default App;
