import { Play } from '@phoenix7dev/play-music/dist/d';
import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { ISongs } from '../config';
import { GameMode, ReelSet } from '../global.d';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap = (
  fn: CallableFunction,
  ...partOne: Helper.RestArguments
) => (...partTwo: Helper.RestArguments): unknown => {
  const args: Helper.RestArguments = [...partOne, ...partTwo];
  if (args.length) {
    return fn(...args);
  }
  return fn();
};

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPINS || mode === GameMode.BUY_FEATURE_FREE_SPINS
  );
};
export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.FREE_ROUND_BONUS;
};

export const isFreeRoundBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_ROUND_BONUS;
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || 25)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
  isPopupOpened,
  transitionStarted,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
  isPopupOpened: boolean;
  transitionStarted: boolean;
}): boolean => {
  if (transitionStarted) {
    return false;
  }

  if (
    (isRegularMode(gameMode) || gameMode === GameMode.BUY_FEATURE) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (
    isFreeSpinMode(gameMode) &&
    ((bonusCurrentRound <= 1 && isSpinInProgress) || !isSlotBusy)
  ) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (
  options: Partial<DropShadowFilterOptions>,
): PIXI.Filter => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getPlayListOnBroken = (isDuringBigWinLoop: boolean): Play[] => {
  if (isDuringBigWinLoop) {
    return [
      { type: ISongs.FreeSpinBGM_Loop, volume: 0 },
      { type: ISongs.BigWin_Loop },
    ];
  }
  return [{ type: ISongs.FreeSpinBGM_Loop }];
};
