import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '002_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '002_02_BaseGameBGM_Melo',
  FreeSpinBGM_Intro = '002_03_FreeSpinBGM_Intro',
  FreeSpinBGM_Loop = '002_03_FreeSpinBGM_Loop',
  BigWin_Intro = '002_04_BigWin_Intro',
  BigWin_Loop = '002_04_BigWin_Loop',
  BigWin_End = '002_05_BigWin_End',
  BigWin_Start = '002_06_BigWin_Start',
  Win_Loop = '002_07_Win_Loop',
  Win_End = '002_08_Win_End',
  FeatureTrigger = '002_09_FeatureTrigger',
  TotalWinBanner = '002_10_TotalWinBanner',
  Scatter_01 = '002_11_Scatter_01',
  Scatter_02 = '002_12_Scatter_02',
  Scatter_03 = '002_13_Scatter_03',
  Scatter_04 = '002_14_Scatter_04',
  Scatter_05 = '002_15_Scatter_05',
  LongSpin = '002_16_LongSpin',
  Mystery_Nom = '002_17_Mystery_Nom',
  Mystery_SP = '002_18_Mystery_SP',
  Mystery_Add = '002_19_Mystery_Add',
  BuyButton = '002_20_BuyButton',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_WIN_BIG',
  SFX_WIN_EPIC = 'SFX_WIN_EPIC',
  SFX_WIN_GREAT = 'SFX_WIN_GREAT',
  SFX_WIN_MEGA = 'SFX_WIN_MEGA',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 60631.5873015873, true],
  [ISongs.BaseGameBGM_Melo]: [62000, 60631.5873015873, true],
  [ISongs.FreeSpinBGM_Intro]: [124000, 18000],
  [ISongs.FreeSpinBGM_Loop]: [143000, 66000, true],
  [ISongs.BigWin_Intro]: [210000, 888.9342403628007],
  [ISongs.BigWin_Loop]: [212000, 32000.045351473917, true],
  [ISongs.BigWin_End]: [246000, 4444.5124716553155],
  [ISongs.BigWin_Start]: [252000, 3372.086167800461],
  [ISongs.Win_Loop]: [257000, 902.630385487555, true],
  [ISongs.Win_End]: [259000, 918.4353741496807],
  [ISongs.FeatureTrigger]: [261000, 1033.3333333333599],
  [ISongs.TotalWinBanner]: [264000, 1783.4693877550762],
  [ISongs.Scatter_01]: [267000, 1771.678004535147],
  [ISongs.Scatter_02]: [270000, 1771.678004535147],
  [ISongs.Scatter_03]: [273000, 1771.678004535147],
  [ISongs.Scatter_04]: [276000, 1771.678004535147],
  [ISongs.Scatter_05]: [279000, 1771.678004535147],
  [ISongs.LongSpin]: [282000, 3980.430839002281],
  [ISongs.Mystery_Nom]: [287000, 2666.6666666666856],
  [ISongs.Mystery_SP]: [291000, 4002.5850340135776],
  [ISongs.Mystery_Add]: [297000, 790.2721088435101],
  [ISongs.BuyButton]: [299000, 342.31292517006295],
  [ISongs.SFX_UI_AutoSpin]: [301000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [303000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [305000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [307000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [309000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [311000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [313000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [315000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [317000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [319000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [322000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [325000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [329000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  [ISongs.FreeSpinBGM_Intro]: 0.3,
  [ISongs.FreeSpinBGM_Loop]: 0.2,
  [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.BigWin_Start]: 0.3,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.Win_End]: 0.7,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.TotalWinBanner]: 0.5,
  [ISongs.Scatter_01]: 0.5,
  [ISongs.Scatter_02]: 0.5,
  [ISongs.Scatter_03]: 0.5,
  [ISongs.Scatter_04]: 0.5,
  [ISongs.Scatter_05]: 0.5,
  [ISongs.LongSpin]: 0.3,
  [ISongs.Mystery_Nom]: 0.5,
  [ISongs.Mystery_SP]: 0.5,
  [ISongs.Mystery_Add]: 0.5,
  [ISongs.BuyButton]: 0.4,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
