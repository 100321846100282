import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
  bonusesId,
} from '../../global.d';
import {
  authGql,
  betsByInitialRoundId,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  setBonuses,
  setBottomContainerTotalWin,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsBuyFeaturePopupOpened,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { loadErrorHandler, loadPixiAssets, parseQuery, queryParams } from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGame = async () => {
      setGameMode(GameMode.REGULAR);
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      // its locally for testing.
      // const userBonusData: ApolloQueryResult<{
      //   userBonuses: UserBonus[];
      // }> = JSON.parse(JSON.stringify(userBonusData1));

      // userBonusData.data.userBonuses.push({
      //   ...(setCurrentBonus() as UserBonus),
      //   isActive: true,
      //   gameMode: GameMode.FREE_ROUND_BONUS,
      //   currentRound: 0,
      //   rounds: 5,
      //   totalWinAmount: 500,
      //   coinAmount: 5,
      //   coinValue: 1,
      //   id: '33e999e6-9f63-4cf8-830c-fc54a058c5b7',
      //   bonusId: '33e999e6-9f63-4cf8-830c-fc54a058c5b7',
      // });

      if (userBonusData.data.userBonuses.length > 0) {
        const buyFeatureBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusesId[GameMode.BUY_FEATURE],
        );
        const freeSpinBonus = userBonusData.data.userBonuses.find(
          (e) =>
            e.bonusId === bonusesId[GameMode.FREE_SPINS] || e.bonusId === bonusesId[GameMode.BUY_FEATURE_FREE_SPINS],
        );
        const freeRoundBonus = userBonusData.data.userBonuses.find(
          (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
        );

        const frbTotalAmount = freeRoundBonus?.totalWinAmount
          ? freeRoundBonus?.totalWinAmount / freeRoundBonus.coinValue
          : 0;

        const fsTotalAmount = freeSpinBonus?.totalWinAmount
          ? freeSpinBonus?.totalWinAmount / freeSpinBonus.coinValue
          : 0;

        if (freeRoundBonus) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
          if (userBonusData.data.userBonuses.length === 1) {
            setBrokenGame(true);
            setGameMode(GameMode.FREE_ROUND_BONUS);
            setCurrentBonus({
              ...(freeRoundBonus as UserBonus),
              isActive: true,
              gameMode: GameMode.FREE_ROUND_BONUS,
              currentRound: 0,
              rounds: freeRoundBonus.rounds,
              totalWinAmount: frbTotalAmount,
              coinAmount: freeRoundBonus.coinAmount,
              coinValue: freeRoundBonus.coinValue,
              id: freeRoundBonus.id,
            });
            if (frbTotalAmount) {
              setBottomContainerTotalWin(frbTotalAmount);
              setFreeRoundsTotalWin(frbTotalAmount);
            }
          } else if (freeSpinBonus?.data.frbReferenceId) {
            setFreeRoundsBonus({
              ...freeRoundBonus,
              isActive: true,
              gameMode: GameMode.FREE_ROUND_BONUS,
              currentRound: 0,
              rounds: freeRoundBonus.rounds,
              totalWinAmount: frbTotalAmount,
              coinAmount: freeRoundBonus.coinAmount,
              coinValue: freeRoundBonus.coinValue,
              id: freeRoundBonus.id,
            });
            setFreeRoundsTotalWin(frbTotalAmount - (fsTotalAmount || 0));
          }
        }

        if (buyFeatureBonus) {
          setBrokenBuyFeature(true);
          setIsBuyFeaturePopupOpened(true);
          setCurrentBonus({
            ...buyFeatureBonus,
            isActive: true,
            currentRound: 0,
          });
          setCurrentBonusId(buyFeatureBonus.id);
        }
        if (freeSpinBonus) {
          setBrokenGame(true);
          setGameMode(GameMode.FREE_SPINS);
          setCurrentBonus({
            ...freeSpinBonus,
            gameMode: GameMode.FREE_SPINS,
            isActive: true,
            currentRound: 0,
          });
          // setFreeSpinsTotalWin(0);
          setFreeSpinsTotalWin(fsTotalAmount);
          setBottomContainerTotalWin(
            freeRoundBonus && freeSpinBonus.data.frbReferenceId ? frbTotalAmount : fsTotalAmount,
          );
          setCurrentBonusId(setCurrentBonus().id);
          const userBonusBetsData = await client.query<{
            bets: ISlotHistoryData;
          }>({
            query: userBonusBetsGql,

            variables: {
              input: {
                filter: {
                  userBonusId: setCurrentBonus().id,
                },
              },
            },
            fetchPolicy: 'network-only',
          });
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: userBonusBetsData.data.bets.pageInfo.count,
            rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
            totalWinAmount: fsTotalAmount,
            // totalWinAmount: 0,
          });
        }
      }
    };
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });
      if (replayBetFreeSpins.data.betsByInitialRoundId.length) {
        const replayFreeSpins = replayBetFreeSpins.data.betsByInitialRoundId.map((e) => e.id);
        setReplayFreeSpinBets(replayFreeSpins);
        setReplayFreeSpinReelSetId(replayBetFreeSpins.data.betsByInitialRoundId[0].reelSetId);
        setReplayBet(replayBetFreeSpins.data.betsByInitialRoundId[0].id);
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, 'auth', async (stage) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.AUTH, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, setSkipIntroScreen(), isSoundOn, setIsSuspended).then(
          () => {
            eventManager.emit(
              EventTypes.SOUND_INITIALIZED,
              AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
            );
          },
        );

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
