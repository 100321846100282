import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Toast } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import {
  configGql,
  setGameMode,
  setIsDuringBigWinLoop,
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSuspended,
} from '../../gql';
import { IConfig } from '../../gql/d';
import { eventManager } from '../../slotMachine/config';
import { getPlayListOnBroken, isFreeSpinMode } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import Menu from '../MenuButton';
import SlotMachineLayout from '../SlotMachineLayout';
import Spin from '../SpinButton';

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn, isShowSuspendedToast } = data!;
  const [isShowContent, setShowContent] = useState(false);
  const isLoading = useReactiveVar(setIsSoundLoading);

  useEffect(() => {
    AudioHowl.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    setShowContent(true);
  }, []);

  if (!isShowContent) return null;

  return (
    <>
      <EventListener />
      <Clock />
      <BuyFeature />
      <SlotMachineLayout />
      <AutoPlaySettingsMenu />
      <Menu />
      <BetSettingsMenu />
      <Spin />
      {AudioHowl.isRestricted &&
        !AudioHowl.restrictionChangedOnIntroScreen &&
        !AudioHowl.hideRestrictionModal &&
        !isShowSuspendedToast &&
        setIsSoundOn() && (
          <Toast
            title={t('soundWarningTitle')}
            text={t('soundWarningText')}
            btnText={t('soundWarningBtn')}
            isLoading={isLoading}
            handleClick={() => {
              const soundToPlay = isFreeSpinMode(setGameMode())
                ? getPlayListOnBroken(setIsDuringBigWinLoop())
                : [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }];

              if (!AudioHowl.isInitialized) {
                eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
              }

              AudioHowl.changeRestriction(
                false,
                soundToPlay,
                () => setIsSoundLoading(true),
                () => setIsShowSoundToast(false),
              );
            }}
          />
        )}
      {isShowSuspendedToast && (
        <Toast
          title={t('suspendedWarningTitle')}
          text={t('suspendedWarningText')}
          btnText={t('suspendedWarningBtn')}
          handleClick={() => {
            setIsSuspended(false);
            AudioHowl.unSuspend();
            const soundToPlay = isFreeSpinMode(setGameMode())
              ? getPlayListOnBroken(setIsDuringBigWinLoop())
              : [{ type: ISongs.BaseGameBGM_Base }, { type: ISongs.BaseGameBGM_Melo, volume: 0 }];

            if (!AudioHowl.isInitialized) {
              eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
            }

            AudioHowl.changeRestriction(
              false,
              soundToPlay,
              () => setIsSoundLoading(true),
              () => setIsShowSoundToast(false),
            );
          }}
        />
      )}
    </>
  );
};

export default GameScreen;
