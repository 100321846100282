import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';
import { TextStyle } from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { BANNER_HEIGHT, BANNER_POSITION_X, BANNER_POSITION_Y, BANNER_WIDTH, buttonPosition } from './config';
import { btnStyle } from './textStyles';

interface IOptions {
  text?: string;
  position?: number;
  styles?: PIXI.TextStyle;
  borderHeight?: number;
  borderWidth?: number;
}

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title: PIXI.Text;

  private subtitle: PIXI.Text;

  private btn: PIXI.Text;

  private additionalText: PIXI.Text;

  private bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: MessageBannerProps) {
    super();
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner();
    this.title = this.initText({
      text: props.title,
      position: props.titlePosition,
      styles: props.titleStyles,
      borderWidth: isMobile ? 400 : 900,
      borderHeight: isMobile ? 250 : 300,
    });
    this.subtitle = this.initText({
      text: props.subtitle,
      position: props.subtitlePosition,
      styles: props.subtitleStyles,
      borderWidth: isMobile ? 400 : 900,
      borderHeight: isMobile ? 250 : 300,
    });
    this.additionalText = this.initText({
      text: props.additionalText,
      position: props.additionalPosition,
      styles: props.additionalStyles,
    });
    this.btn = this.initBtn(props.btnText);
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    this.init();
    if (props.onInitCallback) {
      props.onInitCallback();
    }
  }

  private init(): void {
    this.addChild(this.banner);
    this.addChild(this.title);
    this.addChild(this.subtitle);
    this.addChild(this.additionalText);
    this.addChild(this.btn);
  }

  private initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    banner.width = BANNER_WIDTH;
    banner.height = BANNER_HEIGHT + 10;
    banner.x = -25;
    banner.y = -24;

    return banner;
  }
  // t('freeSpinsMessageBannerTitle'),
  // t('freeSpinsMessageBannerSubtitle'),
  //  t( 'freeSpinsMessageBannerAdditionalText'),
  // t('freeSpinsMessageBannerBtnText'),
  // t('freeSpinsMessageBannerWinText')

  private initText(options: IOptions): PIXI.Text {
    const text = new PIXI.Text(i18n.t(options.text || ''), options.styles);
    text.y = options.position ? options.position : 50;
    text.x = 400;
    text.visible = !!options.text;
    text.anchor.set(0.5, 0.5);
    updateTextScale(text, isMobile ? 700 : 750, isMobile ? 350 : 250);

    return text;
  }

  private initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(i18n.t(btnText || ''), btnStyle);
    btn.y = buttonPosition;
    btn.x = 400;
    btn.visible = !!btnText;
    btn.anchor.set(0.5, 0.5);
    updateTextScale(btn, isMobile ? 700 : 750, isMobile ? 350 : 250);

    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }
}

export default MessageBanner;
