import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { updateTextScale } from '../../../utils';

import { KnobButton, KnobButtonStyle } from './Knob';

class Switch extends PIXI.Container {
  public btn: KnobButton;

  public label: PIXI.Text;

  public isDisabled: boolean;

  private isActive: boolean;

  private SWITCH_LABEL: number;

  private SWITCH_LABEL_SIZE: number;

  constructor(
    {
      label = '',
      labelColor = 0xffffff,
      labelSize = 14,
      textMaxWidth = 90,
      textMobileMaxWidth = 50,
      textMaxHeight = 90,
      textMobileMaxHeight = 50,
    },
    knobStyle?: KnobButtonStyle,
  ) {
    super();
    this.x = 0;
    this.y = 0;
    this.isActive = false;
    this.isDisabled = false;
    this.SWITCH_LABEL = labelColor;
    this.SWITCH_LABEL_SIZE = labelSize;
    this.btn = this.initSwitch(knobStyle);
    this.label = this.initLabel(label);
    updateTextScale(
      this.label,
      isMobile ? textMobileMaxWidth : textMaxWidth,
      isMobile ? textMobileMaxHeight : textMaxHeight,
    );

    this.label.position.set(this.btn.width + 10, this.btn.height / 2);
    this.addChild(this.btn, this.label);

    this.buttonMode = !this.isDisabled;
    this.interactive = !this.isDisabled;
    this.on('pointerdown', this.onButtonDown);
  }

  private initSwitch = (knobStyle?: KnobButtonStyle): KnobButton => {
    const knob = new KnobButton(knobStyle);
    return knob;
  };

  private onButtonDown = (): void => {
    this.setActive(!this.isActive);
  };

  private initLabel = (lab: string): PIXI.Text => {
    const label = new PIXI.Text(lab, {
      fontFamily: 'NotoSans-SemiCondensedBold',
      fill: this.SWITCH_LABEL,
      fontSize: this.SWITCH_LABEL_SIZE,
    });
    label.anchor.set(0, 0.5);
    return label;
  };

  public setActive = (isActive: boolean): void => {
    if (!this.isDisabled) {
      this.isActive = isActive;
      this.btn.setActive(isActive);
    }
  };
}

export default Switch;
