import AudioHowl from '@phoenix7dev/play-music';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_MYSTERY_DURATION,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  eventManager,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_SLOTS,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_MYSTERY_FORMULA,
  REEL_MYSTERY_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
} from '../config';
import Slot from '../slot/slot';
import SpinAnimation from '../spin/spin';
import { addMysteryTimeline } from './addMysteryConfig';
import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public scatter_no = 0;

  private isAnticipationed = false;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = SLOT_WIDTH;
    this.container.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.container.y = 0;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(
      EventTypes.ANTICIPATION_STARTS,
      this.onAnticipationStart.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.REPLACE_MYSTERY_SLOTS,
      this.replaceMystery.bind(this),
    );
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  private replaceMystery(slotId: SlotId): void {
    const slots: Slot[] = _.filter(
      this.getReelStopSlots(Math.round(this.position)),
      (slot) => slot.slotId === SlotId.MS1,
    );
    _.forEach(slots, (slot) => slot.changeTexture(slotId));
  }

  private onAnticipationStart(id: number): void {
    _.forEach(this.slots, (slot) => {
      if (slot.slotId !== SlotId.SC1 && slot.slotId !== SlotId.SC2) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
    if (this.id === id) {
      AudioHowl.play({ type: ISongs.LongSpin, stopPrev: true });
      this.isAnticipationed = true;
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length];
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  public createMysterySpinAnimation(
    isTurboSpin: boolean | undefined,
  ): SpinAnimation {
    this.spinAnimation = this.createSpinAnimation(isTurboSpin);
    this.position %= this.data.length;
    const chain = new AnimationChain();
    chain.appendAnimation(Tween.createDelayAnimation(500));
    const group = new AnimationGroup();

    addMysteryTimeline[this.id].forEach((startTime) => {
      const c = new AnimationChain();
      c.appendAnimation(Tween.createDelayAnimation(startTime));
      const spineAnimation = new SpineAnimation(
        {},
        PIXI.Loader.shared.resources.MysterySymbol_Add_Anim.spineData,
      );
      spineAnimation.spine.scale.set(0.8);
      spineAnimation.spine.x = 75;
      spineAnimation.spine.y = 75;
      spineAnimation.addOnStart(() => {
        this.container.addChild(spineAnimation.spine);
        AudioHowl.play({ type: ISongs.Mystery_Add, stopPrev: true });
        spineAnimation.setAnimation('animation', false);
      });
      spineAnimation.addOnComplete(() => {
        setTimeout(() => this.container.removeChild(spineAnimation.spine));
      });
      c.appendAnimation(spineAnimation);
      group.addAnimation(c);
    });

    chain.appendAnimation(group);
    const starting = this.spinAnimation.getStarting();
    starting.target = this.position + REEL_MYSTERY_SLOTS_AMOUNT;
    starting.duration = BASE_REEL_MYSTERY_DURATION;
    starting.easing = REEL_MYSTERY_FORMULA;

    const rollingDelay = Tween.createDelayAnimation(
      BASE_REEL_STARTING_DURATION,
    );
    rollingDelay.addOnComplete(() => {
      this.changeState(ReelState.ROLLING);
    });

    starting.addOnStart(() => {
      chain.start();
      rollingDelay.start();
      this.changeState(ReelState.STARTING);
    });
    this.spinAnimation.setStarting(starting);
    return this.spinAnimation;
  }

  public createBuyFeatureSpinAnimation(
    isTurboSpin: boolean | undefined,
  ): SpinAnimation {
    this.spinAnimation = this.createSpinAnimation(isTurboSpin);
    this.spinAnimation.getStarting().duration = 0;
    return this.spinAnimation;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingTime = isTurboSpin
      ? TURBO_REEL_ROLLING_DURATION +
        this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      : BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
    const target =
      this.position + INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP + this.id * 5;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay:
        (isTurboSpin
          ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL
          : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin
        ? TURBO_REEL_STARTING_DURATION
        : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + FAKE_ROLLING_SLOTS,
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin
        ? TURBO_REEL_ENDING_DURATION
        : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {}

  private onReelStop(): void {
    if (this.isAnticipationed) {
      AudioHowl.stop({ type: ISongs.LongSpin });
      this.isAnticipationed = false;
    }
    const reelStopSoundList = [
      ISongs.SFX_UI_SpinStop,
      ISongs.Scatter_01,
      ISongs.Scatter_02,
      ISongs.Scatter_03,
      ISongs.Scatter_04,
      ISongs.Scatter_05,
    ];

    if (this.isPlaySoundOnStop) {
      const idx = this.scatter_no > this.id - 2 ? this.scatter_no : 0;
      AudioHowl.play({
        type: reelStopSoundList[idx],
        stopPrev: true,
      });
      this.isPlaySoundOnStop = false;
      this.scatter_no = 0;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle =
      position % this.slots.length === 0
        ? 0
        : this.slots.length - (position % this.slots.length);
    const bottom =
      (this.slots.length - ((position % this.slots.length) - 1)) %
      this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {
    _.forEach(this.slots, (slot) => {
      if (slot.slotId === SlotId.MS1) {
        slot.changeTexture(SlotId.MS1);
      }
    });
  }

  private onReelStarting(previousState: ReelState, newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j];
      slot.y =
        ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT -
        SLOT_HEIGHT;

      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
