import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_TITLE_LOGO_Z_INDEX, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private logo: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelLogo));

  constructor() {
    super();
    this.logo.width = SLOTS_CONTAINER_WIDTH - 210;
    this.logo.scale.y = this.logo.scale.x;
    this.logo.x = (SLOTS_CONTAINER_WIDTH - this.logo.width) / 2;
    this.logo.y = -25;
    this.visible = !setBrokenGame() || setGameMode() === GameMode.FREE_ROUND_BONUS;
    this.addChild(this.logo);

    this.trackZIndex();
  }

  public trackZIndex(): void {
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.zIndex = GAME_CONTAINER_TITLE_LOGO_Z_INDEX;
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.zIndex = 0;
    });
  }
}

export default GameTitle;
