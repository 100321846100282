import { useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig } from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const MysterySymbolFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;
  const infoFeatureMysteryDescs = [
    { key: 'infoFeatureMysteryDesc_1', text: i18n.t('infoFeatureMysteryDesc_1') },
    { key: 'infoFeatureMysteryDesc_2', text: i18n.t('infoFeatureMysteryDesc_2') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureMysteryTitle')}</h1>
      <div className={styles.figcontainer}>
        <div className={styles.fig}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.mystery01)} alt="mystery" />
          <p className={styles.caption}>{t('infoFeatureMysteryCaption1')}</p>
        </div>
        <div className={styles.fig}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.mystery02)} alt="mystery-freespin" />
          <p className={styles.caption}>{t('infoFeatureMysteryCaption2')}</p>
        </div>
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureMysteryDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureFreeSpinDescs = [
    { key: 'infoFeatureFreeSpinDesc_1', text: i18n.t('infoFeatureFreeSpinDesc_1') },
    { key: 'infoFeatureFreeSpinDesc_2', text: i18n.t('infoFeatureFreeSpinDesc_2') },
    { key: 'infoFeatureFreeSpinDesc_3', text: i18n.t('infoFeatureFreeSpinDesc_3') },
    { key: 'infoFeatureFreeSpinDesc_4', text: i18n.t('infoFeatureFreeSpinDesc_4') },
    { key: 'infoFeatureFreeSpinDesc_5', text: i18n.t('infoFeatureFreeSpinDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureFreeSpinDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const BuyAFeature: React.VFC = () => {
  const ref = useRef<HTMLInputElement>(null);
  const i18n = useTranslation();
  const { t } = i18n;

  const calcFontSize = (fontSize: string | number, boxWidth: number, textWidth: number) => {
    if (boxWidth >= textWidth) {
      return Number(fontSize);
    }

    return Math.floor(Number(fontSize) * (boxWidth / textWidth));
  };

  useEffect(() => {
    if (ref.current) {
      const style = new PIXI.TextStyle({
        fontSize: 28,
        fontWeight: '700',
      });
      const text1Width = PIXI.TextMetrics.measureText(t('infoFeatureBuyText1'), style).width;
      const text2Width = PIXI.TextMetrics.measureText(t('infoFeatureBuyText2'), style).width;
      const boxWidth = ref.current.clientWidth - 15;

      if (text1Width > text2Width) {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text1Width);

        ref.current.style.fontSize = `${fontSize}px`;
      } else {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text2Width);

        ref.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, [t]);

  const infoFeatureBuyDescs = [
    { key: 'infoFeatureBuyDesc_1', text: i18n.t('infoFeatureBuyDesc_1') },
    { key: 'infoFeatureBuyDesc_2', text: i18n.t('infoFeatureBuyDesc_2') },
    { key: 'infoFeatureBuyDesc_3', text: i18n.t('infoFeatureBuyDesc_3') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles.fig}>
        <div className={styles.fig__img} ref={ref}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.buyFeatureBtn)} alt="buy-a-feature" />
          <div className={styles.fig__text}>
            <div className={styles.fig__text_gradient}>{t('infoFeatureBuyText1')}</div>
            <div className={styles.fig__text_gradient}>{t('infoFeatureBuyText2')}</div>
          </div>
        </div>
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureBuyDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles.gameRules}>
      <MysterySymbolFeature />
      <FreeSpinFeature />
      {isBuyFeatureEnabled ? <BuyAFeature /> : ''}
    </div>
  );
};

export default Features;
