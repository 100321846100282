import { isMobile } from 'mobile-device-detect';
import 'pixi-spine';

import { EventTypes, FreeSpinsTitleProps, GameMode, ISettledBet, MessageBannerProps, UserBonus } from '../../global.d';
import {
  client,
  getUserBonuses,
  setCurrentBonus,
  setCurrentBonusId,
  setGameMode,
  setIsTimeoutErrorMessage,
  setSlotConfig,
  setUserLastBetResult,
  slotBetGql,
} from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { getSpinResult, isFreeSpinMode, isMobileDevice } from '../../utils';
import ViewContainer from '../components/container';
import {
  DESKTOP_REELS_FRAME_HEIGHT,
  DESKTOP_REELS_FRAME_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LEFT_LINES_NUMBERS_CONTAINER_POSITIONS,
  LINES_NUMBER_CONTAINER_HEIGHT,
  LINES_NUMBER_CONTAINER_WIDTH,
  MOBILE_REELS_FRAME_HEIGHT,
  MOBILE_REELS_FRAME_WIDTH,
  REELS_FRAME_POSITION_X,
  REELS_FRAME_POSITION_Y,
  RIGHT_LINES_NUMBERS_CONTAINER,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsTitle from '../freeSpinsTitle/freeSpinsTitle';
import MessageBanner from '../messageBanner/messageBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import MysteryRevealContainer from '../winAnimations/mysteryRevealContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

window.PIXI = PIXI;

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public mysteryRevealContainer: MysteryRevealContainer;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: PIXI.Sprite;

  public freeSpinsTitle: FreeSpinsTitle | undefined;

  public maskArea: PIXI.Graphics;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.y = 75;
    this.slotsContainer.sortableChildren = true;
    this.maskArea = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    if (SHOW_LINES_NUMBER_CONTAINER) {
      this.leftLinesContainer = new PIXI.Container();
      this.leftLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.leftLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.rightLinesContainer = new PIXI.Container();
      this.rightLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.rightLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.initLinesNumberContainers();
    }
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.mysteryRevealContainer = props.mysteryRevealContainer;
    this.mysteryRevealContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.linesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.addChild(this.slotsContainer);
    this.frame = isMobile ? this.initMobileReelsFrame() : this.initDesktopReelsFrame();
    this.addChild(this.frame);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.mysteryRevealContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS, async () => {
      if (isFreeSpinMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          const res = await client.query<{
            userBonuses: UserBonus[];
          }>({
            query: getUserBonuses,
            variables: { input: { id: setCurrentBonusId() } },
            fetchPolicy: 'network-only',
          });
          const { betId } = res.data.userBonuses[0];
          const bet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: betId } },
            fetchPolicy: 'network-only',
          });
          const reelSet = setSlotConfig().reels.find((e) => e.id === bet.data.bet.reelSetId)!;

          const spinResult = getSpinResult({
            reelPositions: bet.data.bet.result.reelPositions.slice(0, 5),
            reelSet,
            icons: setSlotConfig().icons,
          });
          setUserLastBetResult({
            ...bet.data.bet,
            reelSet,
            result: {
              ...bet.data.bet.result,
              spinResult,
            },
          });
        }
      }
    });
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    if (setIsTimeoutErrorMessage()) return;
    this.addChild(new MessageBanner(props));
  }

  private removeFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    if (this.freeSpinsTitle) this.removeChild(this.freeSpinsTitle);
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsTitle = new FreeSpinsTitle(props);
    this.addChildAt(this.freeSpinsTitle, 3);
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      this.gameTitle.visible = false;
    } else {
      this.gameTitle.visible = true;
    }
  }

  private initDesktopReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = DESKTOP_REELS_FRAME_HEIGHT * ratio;
    frame.width = DESKTOP_REELS_FRAME_WIDTH;
    frame.y = REELS_FRAME_POSITION_Y;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  private initMobileReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = MOBILE_REELS_FRAME_HEIGHT * ratio;
    frame.width = MOBILE_REELS_FRAME_WIDTH;
    frame.y = REELS_FRAME_POSITION_Y + 5;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  public initLinesNumberContainers(): void {
    LEFT_LINES_NUMBERS_CONTAINER_POSITIONS.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.leftLinesContainer!.addChild(sprite);
    });
    this.leftLinesContainer!.x = 0;
    this.addChild(this.leftLinesContainer!);

    RIGHT_LINES_NUMBERS_CONTAINER.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.rightLinesContainer!.addChild(sprite);
    });
    this.rightLinesContainer!.x = SLOTS_CONTAINER_WIDTH + LINES_NUMBER_CONTAINER_WIDTH;
    this.addChild(this.rightLinesContainer!);
  }

  private resize(width: number, height: number): void {
    this.scale.set(
      width / SLOTS_CONTAINER_WIDTH,
      (width * (SLOTS_CONTAINER_HEIGHT / SLOTS_CONTAINER_WIDTH)) / SLOTS_CONTAINER_HEIGHT,
    );
  }
}

export default GameView;
