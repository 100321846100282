import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.mask },
  [SlotId.B]: { default: ResourceTypes.ra },
  [SlotId.C]: { default: ResourceTypes.anubis },
  [SlotId.D]: { default: ResourceTypes.scarab },
  [SlotId.E]: { default: ResourceTypes.eye },
  [SlotId.F]: { default: ResourceTypes.ace },
  [SlotId.G]: { default: ResourceTypes.king },
  [SlotId.H]: { default: ResourceTypes.queen },
  [SlotId.I]: { default: ResourceTypes.jack },
  [SlotId.J]: { default: ResourceTypes.ten },
  [SlotId.K]: { default: ResourceTypes.nine },
  [SlotId.MS1]: {
    default: ResourceTypes.mystery01,
    freespin: ResourceTypes.mystery02,
  },
  [SlotId.WL]: { default: ResourceTypes.man },
  [SlotId.SC1]: { default: ResourceTypes.pyramid },
  [SlotId.SC2]: { default: ResourceTypes.pyramid },
};

export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.blurMask },
  [SlotId.B]: { default: ResourceTypes.blurRa },
  [SlotId.C]: { default: ResourceTypes.blurAnubis },
  [SlotId.D]: { default: ResourceTypes.blurScarab },
  [SlotId.E]: { default: ResourceTypes.blurEye },
  [SlotId.F]: { default: ResourceTypes.blurAce },
  [SlotId.G]: { default: ResourceTypes.blurKing },
  [SlotId.H]: { default: ResourceTypes.blurQueen },
  [SlotId.I]: { default: ResourceTypes.blurJack },
  [SlotId.J]: { default: ResourceTypes.blurTen },
  [SlotId.K]: { default: ResourceTypes.blurNine },
  [SlotId.MS1]: {
    default: ResourceTypes.mystery01,
    freespin: ResourceTypes.mystery02,
  },
  [SlotId.WL]: { default: ResourceTypes.man },
  [SlotId.SC1]: { default: ResourceTypes.pyramid },
  [SlotId.SC2]: { default: ResourceTypes.pyramid },
};

export const MAPPED_SYMBOLS_ANIMATIONS: Record<SlotId, { default: string; freespin?: string }> = {
  [SlotId.A]: { default: 'maskAnimation' },
  [SlotId.B]: { default: 'raAnimation' },
  [SlotId.C]: { default: 'anubisAnimation' },
  [SlotId.D]: { default: 'scarabAnimation' },
  [SlotId.E]: { default: 'eyeAnimation' },
  [SlotId.F]: { default: 'aceAnimation' },
  [SlotId.G]: { default: 'kingAnimation' },
  [SlotId.H]: { default: 'queenAnimation' },
  [SlotId.I]: { default: 'jackAnimation' },
  [SlotId.J]: { default: 'tenAnimation' },
  [SlotId.K]: { default: 'nineAnimation' },
  [SlotId.MS1]: {
    default: 'mystery01Animation',
    freespin: 'mystery02Animation',
  },
  [SlotId.WL]: { default: 'manAnimation' },
  [SlotId.SC1]: { default: 'pyramidAnimation' },
  [SlotId.SC2]: { default: 'pyramidAnimation' },
};

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.mask, src: Resources.getSource(ResourceTypes.mask) },
  { name: ResourceTypes.ra, src: Resources.getSource(ResourceTypes.ra) },
  {
    name: ResourceTypes.anubis,
    src: Resources.getSource(ResourceTypes.anubis),
  },
  {
    name: ResourceTypes.scarab,
    src: Resources.getSource(ResourceTypes.scarab),
  },
  { name: ResourceTypes.eye, src: Resources.getSource(ResourceTypes.eye) },
  {
    name: ResourceTypes.ace,
    src: Resources.getSource(ResourceTypes.ace),
  },
  {
    name: ResourceTypes.king,
    src: Resources.getSource(ResourceTypes.king),
  },
  {
    name: ResourceTypes.queen,
    src: Resources.getSource(ResourceTypes.queen),
  },
  {
    name: ResourceTypes.jack,
    src: Resources.getSource(ResourceTypes.jack),
  },
  {
    name: ResourceTypes.ten,
    src: Resources.getSource(ResourceTypes.ten),
  },
  {
    name: ResourceTypes.nine,
    src: Resources.getSource(ResourceTypes.nine),
  },
  { name: ResourceTypes.man, src: Resources.getSource(ResourceTypes.man) },
  {
    name: ResourceTypes.mystery01,
    src: Resources.getSource(ResourceTypes.mystery01),
  },
  {
    name: ResourceTypes.pyramid,
    src: Resources.getSource(ResourceTypes.pyramid),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.frameMobile,
    src: Resources.getSource(ResourceTypes.frameMobile),
  },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.reelsLight1,
    src: Resources.getSource(ResourceTypes.reelsLight1),
  },
  {
    name: ResourceTypes.reelsLight2,
    src: Resources.getSource(ResourceTypes.reelsLight2),
  },
  {
    name: ResourceTypes.reelsLight3,
    src: Resources.getSource(ResourceTypes.reelsLight3),
  },
  {
    name: ResourceTypes.reelsLight4,
    src: Resources.getSource(ResourceTypes.reelsLight4),
  },
  {
    name: ResourceTypes.reelsLight5,
    src: Resources.getSource(ResourceTypes.reelsLight5),
  },
  {
    name: ResourceTypes.reelsLight1Free,
    src: Resources.getSource(ResourceTypes.reelsLight1Free),
  },
  {
    name: ResourceTypes.reelsLight2Free,
    src: Resources.getSource(ResourceTypes.reelsLight2Free),
  },
  {
    name: ResourceTypes.reelsLight3Free,
    src: Resources.getSource(ResourceTypes.reelsLight3Free),
  },
  {
    name: ResourceTypes.reelsLight4Free,
    src: Resources.getSource(ResourceTypes.reelsLight4Free),
  },
  {
    name: ResourceTypes.reelsLight5Free,
    src: Resources.getSource(ResourceTypes.reelsLight5Free),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  {
    name: 'aceAnimation',
    src: '/animations/symbol_win/ace_anima.json',
  },
  {
    name: 'anubisAnimation',
    src: '/animations/symbol_win/anubis_anima.json',
  },
  {
    name: 'eyeAnimation',
    src: '/animations/symbol_win/eye_anima.json',
  },
  {
    name: 'jackAnimation',
    src: '/animations/symbol_win/jack_anima.json',
  },
  {
    name: 'kingAnimation',
    src: '/animations/symbol_win/king_anima.json',
  },
  {
    name: 'manAnimation',
    src: '/animations/symbol_win/man_anima.json',
  },
  {
    name: 'maskAnimation',
    src: '/animations/symbol_win/mask_anima.json',
  },
  {
    name: 'mystery01Animation',
    src: '/animations/symbol_win/Mystery01_anima.json',
  },
  {
    name: 'mystery02Animation',
    src: '/animations/symbol_win/Mystery02_anima.json',
  },
  {
    name: 'nineAnimation',
    src: '/animations/symbol_win/nine_anima.json',
  },
  {
    name: 'pyramidAnimation',
    src: '/animations/symbol_win/pyramid_anima.json',
  },
  {
    name: 'queenAnimation',
    src: '/animations/symbol_win/queen_anima.json',
  },
  {
    name: 'raAnimation',
    src: '/animations/symbol_win/ra_anima.json',
  },
  {
    name: 'scarabAnimation',
    src: '/animations/symbol_win/scarab_anima.json',
  },
  {
    name: 'tenAnimation',
    src: '/animations/symbol_win/ten_anima.json',
  },
  {
    name: 'MysterySymbol_Add_Anim',
    src: '/animations/adding_mystery/MysterySymbol_Add_Anim.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.reelLogo,
    src: Resources.getSource(ResourceTypes.reelLogo),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introLogo,
    src: Resources.getSource(ResourceTypes.introLogo),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.slotFreeSpinBg,
    src: Resources.getSource(ResourceTypes.slotFreeSpinBg),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActive,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActive),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureInput,
    src: Resources.getSource(ResourceTypes.buyFeatureInput),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.mystery02,
    src: Resources.getSource(ResourceTypes.mystery02),
  },
  {
    name: ResourceTypes.blurMask,
    src: Resources.getSource(ResourceTypes.blurMask),
  },
  {
    name: ResourceTypes.blurRa,
    src: Resources.getSource(ResourceTypes.blurRa),
  },
  {
    name: ResourceTypes.blurAnubis,
    src: Resources.getSource(ResourceTypes.blurAnubis),
  },
  {
    name: ResourceTypes.blurScarab,
    src: Resources.getSource(ResourceTypes.blurScarab),
  },
  {
    name: ResourceTypes.blurEye,
    src: Resources.getSource(ResourceTypes.blurEye),
  },
  {
    name: ResourceTypes.blurAce,
    src: Resources.getSource(ResourceTypes.blurAce),
  },
  {
    name: ResourceTypes.blurKing,
    src: Resources.getSource(ResourceTypes.blurKing),
  },
  {
    name: ResourceTypes.blurQueen,
    src: Resources.getSource(ResourceTypes.blurQueen),
  },
  {
    name: ResourceTypes.blurJack,
    src: Resources.getSource(ResourceTypes.blurJack),
  },
  {
    name: ResourceTypes.blurTen,
    src: Resources.getSource(ResourceTypes.blurTen),
  },
  {
    name: ResourceTypes.blurNine,
    src: Resources.getSource(ResourceTypes.blurNine),
  },
];
