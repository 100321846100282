import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setGameMode,
  setIsBuyFeaturePopupOpened,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsTransitionStarted,
} from '../../gql';
import { isRegularMode } from '../../utils';
import Button from '../button';
import { eventManager } from '../config';

class InfoBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  constructor() {
    super('', 75);
    this.interactive = !this.isDisabled;
    if (setBrokenBuyFeature()) {
      this.gameMode = GameMode.BUY_FEATURE;
    } else if (setBrokenGame()) {
      this.gameMode = setGameMode();
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.btnSheet = PIXI.Loader.shared.resources.infoBtnSprite.spritesheet!;
    this.intent = 'infomation';
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.END_MODE_CHANGE_FADE, () => {
      this.handleDisable();
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'info',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsTransitionStarted() ||
        setIsBuyFeaturePopupOpened(),
    );
  };
}

export default InfoBtn;
