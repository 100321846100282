import _ from 'lodash';

import { EventTypes } from '../../global.d';
import { queryParams } from '../../utils';
import AnimationChain from '../animations/animationChain';
import { ISpinAnimation } from '../animations/d';
import Tween from '../animations/tween';
import {
  FAKE_REPLAY_ROLLING_DURATION,
  FAKE_REPLAY_ROLLING_SLOTS,
  FAKE_ROLLING_DURATION,
  eventManager,
} from '../config';

class SpinAnimation extends AnimationChain {
  constructor(options: ISpinAnimation) {
    super(options);
    const isReplayBet = queryParams.has('replayBetId');
    if (isReplayBet) {
      options.fakeRollingAnimation.duration = isReplayBet ? FAKE_REPLAY_ROLLING_DURATION : FAKE_ROLLING_DURATION;
      options.fakeRollingAnimation.target += FAKE_REPLAY_ROLLING_SLOTS;
    }
    this.appendAnimation(options.startingAnimation);
    this.appendAnimation(options.fakeRollingAnimation);
    this.appendAnimation(options.rollingAnimation);
    this.appendAnimation(options.endingAnimation);
    eventManager.addListener(EventTypes.BREAK_SPIN_ANIMATION, this.breakSpinAnimation.bind(this));
  }

  public getStarting(): Tween {
    return this.animations[0] as Tween;
  }

  public getFakeRolling(): Tween {
    return this.animations[1] as Tween;
  }

  public getRolling(): Tween {
    return this.animations[2] as Tween;
  }

  public getEnding(): Tween {
    return this.animations[3] as Tween;
  }

  public setStarting(tween: Tween): void {
    this.animations[0] = tween;
  }

  public setFakeRolling(tween: Tween): void {
    this.animations[1] = tween;
  }

  public setRolling(tween: Tween): void {
    this.animations[2] = tween;
  }

  public setEnding(tween: Tween): void {
    this.animations[3] = tween;
  }

  private breakSpinAnimation(): void {
    _.forEach(this.animations, (animation) => eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, animation));
  }
}

export default SpinAnimation;
