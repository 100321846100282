import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = 75;
export const BANNER_WIDTH = GAME_CONTAINER_WIDTH + 55;
export const BANNER_HEIGHT = GAME_CONTAINER_HEIGHT + 60;

// export const BANNER_BACKGROUND_COLOR = 0xffffff;

export const titlePosition = 65;
export const subtitlePosition = 160;
export const additionalPosition = 250;
export const buttonPosition = 360;

export const winTitlePosition = 150;
export const winSubtitlePosition = 300;
