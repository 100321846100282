/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import { EventTypes, GameMode, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getSlotGql,
  getUserGql,
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setGameMode,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import { findSubstituteCoinAmount, getSpinResult, isBuyFeatureEnabled, isFreeSpinMode, wrap } from '../../utils';

import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';

const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const { data } = useQuery<{ slot: ISlotData }, { input: { id: string } }>(getSlotGql, {
    variables: { input: { id } },
    onCompleted({ slot }) {
      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0],
        isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
      });
      const reelSet = slot.reels.find((e) => e.id === setUserLastBetResult().reelSetId)!;
      const spinResult = getSpinResult({
        reelPositions: setUserLastBetResult().result.reelPositions.slice(0, 5),
        reelSet,
        icons: setSlotConfig().icons,
      });
      const lastBet = {
        ...setUserLastBetResult(),
        reelSet,
        result: {
          ...setUserLastBetResult().result,
          spinResult,
        },
      };
      setUserLastBetResult(lastBet);
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(slot.reels[0].id);

      let coinValue;
      let coinAmount;
      if (setBrokenGame() || setBrokenBuyFeature()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === userData?.user.balance.currency)
          ?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * lines.length);
      SlotMachine.initSlotMachine(slot, wrap(setIsSpinInProgress, false), wrap(setIsSlotBusy, false));
      slotMachine.current = SlotMachine.getInstance();
    },
  });

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      const application = slotMachine.current.getApplication();
      pixiContainerRef.current?.appendChild(slotMachine.current.getApplication().view);
      resize(application)();
      window.addEventListener(EventTypes.RESIZE, resize(application));
      return () => window.removeEventListener(EventTypes.RESIZE, resize(application));
    }
    return undefined;
  }, [!!data]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  return <div className={styles.canvasWrapper} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
